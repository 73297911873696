import React from "react";
import { Typography } from "../../components/typography/Typography";
import DataTable from "react-data-table-component";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const PaymentsTable = () => {
  const dummyData = [
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      student: "Mawuli Togbor",
      date: "22/07/21",
      amount: "$2500",
      invoice: "IN332942",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      student: "Mawuli Togbor",
      date: "22/07/21",
      amount: "$2500",
      invoice: "IN332942",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      student: "Mawuli Togbor",
      date: "22/07/21",
      amount: "$2500",
      invoice: "IN332942",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      student: "Mawuli Togbor",
      date: " 22/07/21",
      amount: "$2500",
      invoice: "IN332942",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      student: "Mawuli Togbor",
      date: "22/07/21",
      amount: "$2500",
      invoice: "IN332942",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      student: "Mawuli Togbor",
      date: "23/07/21",
      amount: "$2500",
      invoice: "IN332942",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      student: "Mawuli Togbor",
      date: "22/07/21",
      amount: "$2500",
      invoice: "IN332942",
    },
  ];
  const columns = [
    {
      name: "",
      cell: (row: any) => (
        <img src={row.image} className="rounded-full h-8 w-8" />
      ),
    },
    {
      name: <Typography.MediumText text="Student" xStyle="text-defaultText" />,
      cell: (row: any) => <span className="">{row.student} </span>,
    },
    {
      name: <Typography.MediumText text="Date" xStyle="text-defaultText" />,
      cell: (row: any) => <span>{row.date}</span>,
    },

    {
      name: <Typography.MediumText text="Amount" xStyle="text-defaultText" />,
      cell: (row: any) => <span>{row.amount}</span>,
    },
    {
      name: <Typography.MediumText text="Invoice" xStyle="text-defaultText" />,
      cell: (row: any) => <span className="   ">{row.invoice}</span>,
    },
  ];
  return (
    <div className="w-full  bg-white rounded-2xl shadow-md">
      <div className="relative py-4 px-4">
        <input
          className="w-full lg:w-80 h-8 px-4 outline outline-2 outline-defaultBlue_20 focus:border-none rounded-xl"
          placeholder="Search "
        />
        <div className="absolute bottom-7 left-auto right-10 md:left-auto md:right-10  lg:left-auto lg:right-[400px]">
          <MagnifyingGlassIcon className="h-5 w-5 " />
        </div>
      </div>
      <div className="mt-2">
        <DataTable
          columns={columns}
          selectableRows
          data={dummyData}
          //  progressPending={pending}
          pagination
        />
      </div>
    </div>
  );
};

export default PaymentsTable;
