import React, { useState } from "react";
import ProSideBar from "../../components/sidebar/sidebar";
import activateicon from "../../images/activateicon.png";
import tourimage from "../../images/tourimage.png";
import contactsupport from "../../images/contactsupport.png";
import { Typography } from "../../components/typography/Typography";
import { Image } from "react-bootstrap";
import { useGeneralContext } from "../../context/generalContext";
import { useNavigate } from "react-router-dom";
import CustomNavbar from "../../components/navbar/customNavbar";


const GetStarted: React.FC = () => {

  const { userData, onboardStep } = useGeneralContext()
  const navigate = useNavigate()


  return (
    <>
      <div className="flex overflow-hidden h-screen w-full">
        {/* sidebar */}
        <ProSideBar dashIcon1="dashIconActive" dashboardActive1="active" />

        {/* content */}
        <div className="lg:flex lg:flex-col lg:flex-1 w-full overflow-hidden h-full">
          <CustomNavbar />

          {/* main content */}
          <div className="lg:flex lg:justify-center lg:items-center text-defaultText h-full px-4 lg:px-0 overflow-y-auto pb-20">
            <div className="2xl:w-[950px] xl:w-[850px] lg:w-[850px] md:w-[650px] w-full">
              <Typography.HeadingThree
                text={`Hi, ${userData && userData?.firstName} 👋 Welcome to RoboCentre`}
                xStyle="text-defaultBlue text-center"
              />
              <Typography.MediumText
                text="Pick up right where you left off. Track the progress of your Students and stay up to date."
                xStyle="text-center"
              />

              <div className="mt-[30px] w-full lg:grid grid-cols-3 gap-[40px]">
                <div className="mt-[20px] w-full">
                  <div
                    className="bg-white text-defaultText shadow-sm rounded-[20px] h-[265px] px-[30px] flex flex-col justify-center items-center cursor-pointer ease-in duration-200 hover:scale-105 border-[1px] border-transparent hover:border-defaultBlue" onClick={() => navigate("/activate-account")}>
                    <Image src={activateicon} alt="...add" fluid />

                    <Typography.SmallText
                      text="Activate Account"
                      xStyle="font-semibold mt-[42px] text-center"
                    />
                    <Typography.SmallText
                      text={onboardStep === 0 ? "0/3 Complete" : onboardStep === 1 ? "1/3 Complete" : onboardStep === 2 ? "2/3 Complete" : "3/3 Complete"}
                    />
                  </div>
                </div>

                <div className="mt-[20px] w-full">
                  <div
                    className="bg-white text-defaultText shadow-sm rounded-[20px] h-[265px] px-[30px] flex flex-col justify-center items-center cursor-pointer ease-in duration-200 hover:scale-105 border-[1px] border-transparent hover:border-defaultBlue">
                    <Image src={tourimage} alt="...add" fluid />

                    <Typography.SmallText
                      text="Take a Quick Tour"
                      xStyle="font-semibold mt-[42px] text-center"
                    />
                  </div>
                </div>

                <div className="mt-[20px] w-full">
                  <div className="bg-white text-defaultText shadow-sm rounded-[20px] h-[265px] px-[30px] flex flex-col justify-center items-center cursor-pointer ease-in duration-200 hover:scale-105 border-[1px] border-transparent hover:border-defaultBlue">
                    <Image src={contactsupport} alt="...add" fluid />

                    <Typography.SmallText
                      text="Contact Support"
                      xStyle="font-semibold mt-[42px] text-center"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetStarted;
