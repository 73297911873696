import { DatePicker, Form, Input, message, Radio, Skeleton, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import BaseService from '../../../helpers/baseServices'
import { global_variables } from '../../../helpers/globalVariables'
import { RiSchoolLine } from "react-icons/ri";
import { GetAgeRange } from '../../../functions/getAgeRange';
import { HexToRGBA } from '../../../functions/hexToRgb';
import { useForm } from 'antd/es/form/Form';
import { useGeneralContext } from '../../../context/generalContext';
import ShowAlert from '../../../components/alerts/all_alerts';


const AcademicsTab = () => {

    const [form] = useForm()
    const { allMySchools, getMySchools } = useGeneralContext()
    const [isBusy, setIsBusy] = useState(false)
    const [fetchingAssessTypes, setFetchingAssessTypes] = useState(true)
    const [assessmentTypesList, setAssessmentTypesList] = useState([])

    const [fetchingCurriculums, setFetchingCurriculums] = useState(false)
    const [curriculumsList, setCurriculumsList] = useState([])

    //form
    const [selectedCuriculums, setSelectedCuriculums] = useState<any>([])

    //getAllAssessmentTypes
    const getAllAssessmentTypes = async () => {
        setFetchingAssessTypes(true)

        try {
            const response = await BaseService.get_api(`${global_variables.get_assessment_types}`)
            // console.log("assesTypesRes:", response?.data)
            setAssessmentTypesList(response?.data?.payload)
            setFetchingAssessTypes(false)
        } catch (error) {
            console.log("assesTypesErr:", error)
        }
    }

    const getAllCurriculums = async () => {
        setFetchingCurriculums(true)

        try {
            const response = await BaseService.get_api(`${global_variables.get_all_curriculums}`)
            console.log("curriculumRes:", response?.data)
            // remove empty
            const mapped = response?.data?.payload?.filter((item: any) => item?.curriculumLevelData.length !== 0)
            setCurriculumsList(mapped)
            setFetchingCurriculums(false)
        } catch (error) {
            console.log("curriculumErr:", error)
        }
    }

    //onload
    useEffect(() => {
        getAllAssessmentTypes()

        getAllCurriculums()
    }, [])



    //submit form
    const onFormFinished = async (values: any) => {

        if (selectedCuriculums.length === 0) {
            message.warning("Please select at least one curriculum")
        } else {

            //crate academic term
            let academic_info = {
                "schoolId": allMySchools?._id,
                "name": values?.name,
                "startsOn": new Date(values?.startsOn).toISOString(),
                "endsOn": new Date(values?.endsOn).toISOString(),
                "year": new Date(values?.startsOn).getFullYear().toString()
            }

            console.log("academicInfo:", academic_info)

            setIsBusy(true)

            try {
                const response = await BaseService.post_api(global_variables.create_academic_term, academic_info)
                console.log("academicRes:", response?.data)

                //upate school info
                let update_info = {
                    "_id": allMySchools?._id,
                    "status": "PUBLISH",
                    "curriculumLevelIds": selectedCuriculums,
                    "currentAccademicCalender": response?.data?.payload?._id,
                    "accademicAssesmentId": values?.accademicAssesmentId
                }

                try {
                    const response = await BaseService.put_api(global_variables.update_school, update_info)
                    console.log("updateRes:", response?.data)
                    message.success("Information has been saved successfully")
                    form.resetFields()
                    setIsBusy(false)
                    getMySchools()
                } catch (error: any) {
                    console.log("updateErr:", error)
                    setIsBusy(false)
                    ShowAlert.error_alert("Error", error?.response?.data?.error || "An unknown error occured. Please retry", "Retry")
                }

            } catch (error: any) {
                console.log("academicErr:", error)
                setIsBusy(false)
                ShowAlert.error_alert("Error", error?.response?.data?.error || "An unknown error occured. Please retry", "Retry")
            }

        }
    };

    //handleCurrSelect
    const handleCurrSelect = (selected: any) => {
        let tempArr = selectedCuriculums

        if (tempArr.includes(selected?._id)) {
            tempArr = tempArr.filter((item: any) => item !== selected?._id);
        } else {
            tempArr.push(selected?._id)
        }

        console.log(tempArr)
        setSelectedCuriculums(tempArr)
    }


    return (
        <>
            <p className='text-[21px] font-bold text-defaultText'>Academics</p>

            <hr className='my-[20px] outline-[2px] outline-[#F0EFF8]' />

            <Spin spinning={isBusy}>
                <Form
                    name="principalForm"
                    onFinish={onFormFinished}
                    layout="vertical"
                >

                    <div>
                        <p className='text-[18px] font-bold text-defaultText'>Curriculum</p>

                        {/* curriculum list */}
                        <div className='mt-[10px]'>
                            {
                                fetchingCurriculums ?
                                    <div>
                                        <Skeleton paragraph={{ rows: 5 }} active />
                                    </div>
                                    :
                                    curriculumsList.map((item: any, i: number) => (
                                        <div key={i} className='mb-[20px]'>
                                            <p className='text-[16px] font-bold text-defaultText'>{item?.name}</p>

                                            <div className="mt-[5px] w-full grid grid-cols-2 gap-[10px]">
                                                {
                                                    item?.curriculumLevelData && item?.curriculumLevelData.map((currItem: any, index: number) => (
                                                        <label key={index} className={`w-full border-[1px] border-transparent p-[16px] rounded-[10px] hover:border-defaultBlue cursor-pointer shadow-sm flex justify-between`} style={{ backgroundColor: HexToRGBA(currItem?.color, 0.1) }}>
                                                            <div>
                                                                <RiSchoolLine className='h-[25px] w-[25px] text-defaultBlue' />
                                                                <p className='text-[16px] font-[400] text-defaultText mt-[10px]'>{currItem?.name}</p>
                                                                <p className='text-[12px] font-[500] text-[#9D9CC2]'>{GetAgeRange(currItem?.ageGroup)}</p>
                                                            </div>

                                                            <div>
                                                                <input type="checkbox" className='h-[15px] w-[15px] cursor-pointer' onChange={() => handleCurrSelect(currItem)} />
                                                            </div>
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>

                    <hr className='my-[20px] outline-[2px] outline-[#F0EFF8]' />

                    <div>
                        <p className='text-[16px] font-bold text-defaultText'>Academic Calender</p>

                        <Form.Item
                            label="Term Name"
                            name="name"
                            rules={[{ required: true, message: 'Please enter term name' }]}
                        >
                            <Input className='h-[44px]' placeholder='Term One' />
                        </Form.Item>

                        <div className="grid grid-cols-2 w-full gap-[20px]">
                            <div className="w-full">
                                <Form.Item
                                    className='w-full'
                                    label="Start Date"
                                    name="startsOn"
                                    rules={[{ required: true, message: 'Please choose a start' }]}
                                >
                                    <DatePicker className='h-[44px] w-full' placeholder='Select' />
                                </Form.Item>
                            </div>

                            <div className="w-full">
                                <Form.Item
                                    className='w-full'
                                    label="End Date"
                                    name="endsOn"
                                    rules={[{ required: true, message: 'Please choose an end date' }]}
                                >
                                    <DatePicker className='h-[44px] w-full' placeholder='Select' />
                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    <hr className='my-[20px] outline-[2px] outline-[#F0EFF8]' />

                    <div>
                        <p className='text-[16px] font-bold text-defaultText'>Other Academic Function</p>

                        <div className="mt-[10px] text-defaultText">
                            {
                                fetchingAssessTypes ?
                                    <div>
                                        <Skeleton paragraph={{ rows: 1 }} active />
                                    </div>
                                    :
                                    <Form.Item
                                        label="Assessment calculation"
                                        name="accademicAssesmentId"
                                        rules={[{ required: true, message: 'Please select one of the options' }]}
                                    >
                                        <Radio.Group className='flex flex-wrap gap-[30px] items-center' disabled={fetchingAssessTypes}>
                                            {
                                                assessmentTypesList.map((item: any, i: number) => (
                                                    <Radio key={i} value={item?._id}><p className='text-[16px] font-[400] text-defaultText'>{item?.name || item?.status}</p></Radio>
                                                ))
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                            }

                            <p className='text-[12px] mt-[10px] font-[500] text-[#9D9CC2]'>
                                When using percentage weights the task categories are given a weight that totals
                                100%. When using absolute weights the task categories are given a weight from
                                1-100 whose total can exceed 100 and is not based on percentages but on the
                                absolute values.
                            </p>
                        </div>
                    </div>

                    <Form.Item className='mt-[40px]'>
                        <button type='submit' className='h-[50px] w-full rounded-[40px] bg-defaultBlue text-white'>
                            <p className='text-[16px] font-[700]'>Save</p>
                        </button>
                    </Form.Item>
                </Form>
            </Spin>
        </>
    )
}

export default AcademicsTab