export const regionsList = [
  { lable: "Ahafo", value: "AHAFO" },
  { lable: "Ashanti", value: "ASHANTI" },
  { lable: "Bono East", value: "BONO EAST" },
  { lable: "Brong Ahafo", value: "BRONG AHAFO" },
  { lable: "Central", value: "CENTRAL" },
  { lable: "Eastern", value: "EASTERN" },
  { lable: "Greater Accra", value: "GREATER ACCRA" },
  { lable: "North East", value: "NORTH EAST" },
  { lable: "Northern", value: "NORTHERN" },
  { lable: "Oti", value: "OTI" },
  { lable: "Savannah", value: "SAVANNAH" },
  { lable: "Upper East", value: "UPPER EAST" },
  { lable: "Upper West", value: "UPPER WEST" },
  { lable: "Volta", value: "VOLTA" },
  { lable: "Western", value: "WESTERN" },
  { lable: "Western North", value: "WESTERN NORTH" },
];
