import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const AccountSettings:React.FC = () => {
  return (
    <div>
      <div className="py-4 text-lg font-bold text-defaultText">Account</div>
      <div className=" px-[20px] lg:px-[150px] py-[60px] w-full  rounded-xl bg-white shadow-md  ">
        <Row className="items-center">
          <Col md={4} lg={4}>
            <p>Email</p>
          </Col>
          <Col md={8} lg={8} >
            <Form.Control
              placeholder="Emaiaiaii@gmail.com"
              type="email"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              className=""
            />
          </Col>
        </Row>
        <Row className="items-center mt-3">
          <Col md={4} lg={4}>
            <p>password</p>
          </Col>
          <Col md={8} lg={8}>
            <Form.Control
              placeholder="******"
              type="password"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AccountSettings;
