import React, { useEffect, useState } from 'react'
import { Form, Input, message, Spin } from 'antd';
import PhoneInput from "react-phone-number-input";
import { useGeneralContext } from '../../../context/generalContext';
import ShowAlert from '../../../components/alerts/all_alerts';
import BaseService from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';
import { useForm } from 'antd/es/form/Form';


const PrinicpalInformation = () => {

    const { getMySchools, allMySchools } = useGeneralContext()
    const [isBusy, setIsBusy] = useState(false)
    const [form] = useForm()

    // onload
    useEffect(() => {
        if (allMySchools) {
            form.setFieldsValue({
                firstName: allMySchools?.principal?.firstName,
                middleName: allMySchools?.principal?.middleName,
                lastName: allMySchools?.principal?.lastName,
                phone: allMySchools?.principal?.phone,
                email: allMySchools?.principal?.email,
            });
        }
    }, [allMySchools, form]);

    //on update click
    const onFormFinished = async (values: any) => {

        let principal_info = {
            "_id": allMySchools?._id,
            "principal": {
                "firstName": values?.firstName,
                "lastName": values?.lastName,
                "middleName": values?.middleName,
                "phone": values?.phone,
                "email": values?.email
            }
        }
        if (!principal_info?.principal?.middleName) {
            delete principal_info?.principal?.middleName
        }

        console.log('principalInfo:', { principal_info });

        setIsBusy(true)

        try {
            const response = await BaseService.put_api(global_variables.update_school, principal_info)
            console.log("pricipalRes:", response?.data)
            message.success("School information has been updated")
            form.resetFields()
            setIsBusy(false)
            getMySchools()
        } catch (error: any) {
            console.log("principalErr:", error)
            setIsBusy(false)
            ShowAlert.error_alert("Error", error?.response?.data?.error || "An unknown error occured. Please retry", "Retry")
        }
    };




    return (
        <>
            <p className='text-[21px] font-bold text-defaultText'>Principal Information</p>

            <hr className='my-[20px] outline-[2px] outline-[#F0EFF8]' />

            <Spin spinning={!allMySchools || isBusy}>
                <Form
                    form={form}
                    name="principalForm"
                    onFinish={onFormFinished}
                    layout="vertical"
                >
                    <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[{ required: true, message: 'Please input your first name!' }]}
                    >
                        <Input className='h-[44px]' placeholder='John' />
                    </Form.Item>

                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[{ required: true, message: 'Please input your last name!' }]}
                    >
                        <Input className='h-[44px]' placeholder='Doe' />
                    </Form.Item>

                    <Form.Item
                        label="Middle Name"
                        name="middleName"
                        rules={[{ message: 'Please input your middle name!' }]}
                    >
                        <Input className='h-[44px]' placeholder='Optional' />
                    </Form.Item>

                    <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <PhoneInput
                            className="w-full border border-gray-300 rounded-[5px] px-2 h-[44px]"
                            defaultCountry="GH"
                            inputStyle={{ width: "100%" }}
                            placeholder='0550001111'
                            numberInputProps={{
                                className: "focus:outline-none",
                            }}
                            onChange={() => { }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'Please enter a valid email!' }]}
                    >
                        <Input className='h-[44px]' placeholder='example@schoolname.com' />
                    </Form.Item>

                    <Form.Item className='mt-[40px]'>
                        <button type='submit' className='h-[50px] w-full rounded-[40px] bg-defaultBlue text-white'>
                            <p className='text-[16px] font-[700]'>Save</p>
                        </button>
                    </Form.Item>
                </Form>
            </Spin>
        </>
    )
}

export default PrinicpalInformation
