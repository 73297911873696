import React from "react";
import DataTable from "react-data-table-component";
import { Typography } from "../../components/typography/Typography";

const Recentenrollment:React.FC = () => {
  const dummyData = [
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Mawuli Togbor",
      date: "23 Feb,2022",
      Grade: "Grade 2",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Kwadwo Agyapong",
      date: "23 Feb,2022",
      Grade: "Grade 2",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Maxwel Atalla",
      date: "23 Feb,2022",
      Grade: "Grade 2",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Prince Togbor",
      date: "23 Feb,2022",

      Grade: "Grade 2",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Fred Alottey",
      date: "23 Feb,2022",
      Grade: "Grade 2",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Fred Alottey",
      date: "23 Feb,2022",
      Grade: "Grade 2",
    },
  ];
  const columns = [
    {
      name: "",
      cell: (row: any) => (
        <img src={row.image} className="rounded-full h-8 w-8" />
      ),
    },
    {
      name: <Typography.MediumText text="Name" xStyle="text-defaultBlue" />,
      cell: (row: any) => <span >{row.name}</span>,
    },
    {
      name:  <Typography.MediumText text="Date" xStyle="text-defaultBlue" />,
      cell: (row: any) => <span>{row.date}</span>,
    },

    {
      name:  <Typography.MediumText text="Grade" xStyle="text-defaultBlue" />,
      cell: (row: any) => <span>{row.Grade}</span>,
    },
  ];

  return (
    <div className="bg-white w-full  shadow-lg rounded-2xl px-4 py-4 ">
      <DataTable
        columns={columns}
        selectableRows

        data={dummyData}
        //  progressPending={pending}
        pagination
      />
    </div>
  );
};

export default Recentenrollment;
