import React from "react";
import ProSideBar from "../../components/sidebar/sidebar";
import CustomNavbar from "../../components/navbar/customNavbar";

const Calender = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* sidebar */}
      <ProSideBar CalendarDaysIcon="calendarActive" calendarActive="active" />

      <div className="flex flex-col flex-1 w-full overflow-x-hidden h-full ">
        <CustomNavbar />
        {/* maincontent */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <div className="px-8 ">
            <div className=" mt-3">
              <span className="text-[21px] font-bold">Calender</span>
            </div>
          </div>

        </div>


      </div>
    </div>
  );
};

export default Calender;
