import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

const Airtel:React.FC = () => {
  return (
    <div className='mt-3'>
      <Row>
        <Col md={6}>
        <p className='text-xs text-gray-400'>contact</p>
          <Form.Control
            placeholder="0246678798"
            type="number"
            id=""
            aria-describedby=""
            className=" " 
          />
        </Col>
        <Col md={6} className='mt-[12px] lg:mt-[0px] md:mt-[0px]'>
          <p className='text-xs text-gray-400'>Account Name</p>
          <Form.Control
            placeholder="John Doe"
            type="name"
            id=""
            aria-describedby=""
            className=" "
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} className='mt-3'>
          <p className='text-xs text-gray-400'>Email</p>
          <Form.Control
            placeholder="Emailk@gmail.com"
            type="email"
            id=""
            aria-describedby=""
            className=" "
          />

        </Col>
      </Row>
      <div className="flex mt-3">
        <Form>
          <Form.Check />
        </Form>

        <p className="text-sm pl-2 text-defaultText">use as default payment</p>
      </div>
    </div>

 
  )
}

export default Airtel