import React from "react";
import welcomeimage from "../../images/welcomeimage.png";
import arrowup from "../../images/arrowup.png";
import iconperson from "../../images/iconperson.png";
import classicon from "../../images/classicon.png";
import hand from "../../images/hand.png";
import imgfirst from "../../images/imgfirst.png";
import DataTable from "react-data-table-component";

const WelcomeCard: React.FC = () => {
  return (
    <div className="flex relative flex-col px-4 py-4 md:w-full bg-white shadow-lg rounded-2xl">
      <div className="flex ">
        <span className="font-semibold text-defaultBlue">👋Hello!</span>
        <div className="">
          <span className="ml-2 font-semibold text-defaultBlue">Welcome</span>
        </div>
        <div className="absolute  hidden md:block  md:right-0 md:bottom-2 md:top-0 lg:right-0 lg:top-0  lg:bottom-2 lg:block hover:translate-y-1 ">
          <img src={welcomeimage} />
        </div>
      </div>

      <div className="flex">  
        <p className="text-sm">Your students completed </p>
        <p className="text-secondary-green text-sm pl-1 font-semibold">80%</p>
        <p className="text-sm pl-1 ">of the Tasks</p>
      </div>
      <div className="flex">
        <p className="text-sm">Progress is</p>
        <p className="text-sm text-secondary-green pl-1 font-semibold">Good</p>
      </div>
    </div>
  );
};

const EarningCard: React.FC = () => {
  return (
    <div className=" md:w-full bg-defaultBlue px-4 py-4  rounded-2xl shadow-lg ">
      <p className="text-sm text-white">Total Earning</p>
      <div className="mt-2">
        <span className="text-white text-[21px]  font-bold">$2,3446</span>
      </div>
      <div className="flex mt-2">
        <p className="text-xs text-white">2.3%</p>
        <p className=" text-xs pl-4 text-gray-400">than last month</p>
      </div>
    </div>
  );
};

const TotalCard: React.FC = () => {
  return (
    <div className="md:w-full bg-white   rounded-2xl shadow-lg px-4 py-2">
      <p className="text-sm">Total Students</p>

      <div className="flex justify-between mt-2">
        <span className="text-[21px] font-bold text-defaultText">4556</span>
        <div className="bg-defaultBlue bg-opacity-[10%] rounded-md h-8 w-8 px-2 py-2">
          <img src={iconperson} />
        </div>
      </div>
      <div className="flex mt-2 text-xs">
        <span className="text-secondary-green font-bold">2.3%</span>
        <img src={arrowup} className="pl-1 h-3" />
        <span className="pl-2 text-unfilledText">than last month</span>
      </div>
    </div>
  );
};

const ClassCard: React.FC = () => {
  return (
    <div className="">
      <div className="bg-white md:w-full  rounded-2xl py-2 px-4 shadow-lg">
        <div className="">
          <p className="">Classes</p>
        </div>
        <div className="flex justify-between mt-2 ">
          <span className="text-[21px] font-bold text-defaultText">12</span>
          <img src={classicon} className="h-8 w-8" />
        </div>
        <div className="flex mt-2 text-xs">
          <span className="text-secondary-green font-bold">2.3%</span>
          <img src={arrowup} className="pl-1 h-3" />
          <span className="pl-2 text-unfilledText">than last month</span>
        </div>
      </div>
    </div>
  );
};
const TotalLearningCard: React.FC = () => {
  return (
    <div className="bg-white md:w-full rounded-2xl shadow-lg py-2 px-4   ">
      <span>Total learning Hours</span>
      <div className="flex justify-between mt-2">
        <span className="text-[21px] font-bold text-defaultText">2344</span>
        <img src={hand} className="h-8 w-8" />
      </div>
      <div className="flex mt-2 text-xs">
        <span className="text-secondary-green font-bold">2.3%</span>
        <img src={arrowup} className="pl-1 h-3" />
        <span className="pl-2 text-unfilledText">than last month</span>
      </div>
    </div>
  );
};

const RevenuePerformance: React.FC = () => {
  return (
    <div className="bg-white md:w-full h-[300px] shadow-lg rounded-2xl px-4 py-2 ">
      <div>
        <span className="font-bold text-defaultText">Revenue Performance</span>
      </div>
    </div>
  );
};

const CalenderCard: React.FC = () => {
  return (
    <div className="bg-gray-300 w-[100px] h-[400px]">
      <span>june</span>
    </div>
  );
};

export const Cards = {
  WelcomeCard,
  EarningCard,
  TotalCard,
  ClassCard,
  TotalLearningCard,
  RevenuePerformance,
  // EnrollmentCard,
  CalenderCard,
};
