import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import GetStarted from "./pages/Get_started/get_started";
import Dashboard from "./pages/dashboard/dashboard";
import "./css/App.css";
import "./css/GlobalStyles.css";
import YearGroup from "./pages/year group/year_group";
import Classes from "./pages/classes/classes";
import Students from "./pages/students/students";
import Payments from "./pages/payments/payments";
import AcademicYear from "./pages/Academic_Year/academic_year";
import Reports from "./pages/reports/reports";
import Calender from "./pages/calender/calender";
import Settings from "./pages/settings/settings";
import ProtectedRoutes from "./router/router";
import { GeneralContextProvider } from "./context/generalContext";
import ActivateAccount from "./pages/activateAccount/activateAccount";
import UpdateSchoolInfo from "./pages/updateSchoolInfo/updateSchoolInfo";

function App() {
  return (
    <GeneralContextProvider>
      <div>
        <Router>
          <Routes>
            <Route path="/" element={<ProtectedRoutes><GetStarted /></ProtectedRoutes>} />
            <Route path="/activate-account" element={<ProtectedRoutes><ActivateAccount /></ProtectedRoutes>} />
            <Route path="/update-school-info" element={<ProtectedRoutes><UpdateSchoolInfo /></ProtectedRoutes>} />
            <Route path="/dashboard" element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>} />
            <Route path="/calendar" element={<ProtectedRoutes><Calender /></ProtectedRoutes>} />
            <Route path="/year_group" element={<ProtectedRoutes><YearGroup /></ProtectedRoutes>} />
            <Route path="/classes" element={<ProtectedRoutes><Classes /></ProtectedRoutes>} />
            <Route path="/students" element={<ProtectedRoutes><Students /></ProtectedRoutes>} />
            <Route path="/payments" element={<ProtectedRoutes><Payments /></ProtectedRoutes>} />
            <Route path="/academic_year" element={<ProtectedRoutes><AcademicYear /></ProtectedRoutes>} />
            <Route path="/reports" element={<ProtectedRoutes><Reports /></ProtectedRoutes>} />
            <Route path="/settings" element={<ProtectedRoutes><Settings /></ProtectedRoutes>} />

          </Routes>
        </Router>
      </div>
    </GeneralContextProvider>
  );
}

export default App;
