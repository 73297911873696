import React from 'react'
import MobileTabs from './mobile_money_tabs'
 
const MobileMoney:React.FC = () => {
  return (
    <div>
      <div className='mt-2'>
      <span className='text-xs text-gray-400'>
         select network
      </span>
      <div className='flex gap-2 '>
        <MobileTabs/>

      </div>
      </div>
    </div>
  )
}

export default MobileMoney