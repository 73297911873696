import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import IconCalenders from "../../images/Iconcalenders.png";

const CreditCard: React.FC = () => {
  return (
    <div>
      <Row className="mt-3">
        <Col md={6}>
          <p className="text-xs text-gray-400 ">card Number*</p>
          <Form.Control
            placeholder="3256-5468-1264-5646"
            type="number"
            id=""
            aria-describedby=""
            className=" mt-2"
          />
        </Col>
        <Col md={6} className="mt-[12px] lg:mt-[0px] md:mt-[0px]">
          <p className="text-xs text-gray-400">Holder Name</p>
          <Form.Control
            placeholder="Kweku Bans" 
            type="name"
            id=""
            aria-describedby=""
            className=" mt-2"
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={6}>
          <p className="text-xs text-gray-400">Expiry Date*</p>
          <Form.Control
            placeholder="23/2030"
            type=""
            id=""
            aria-describedby=""
            className=" mt-2"
          />
        </Col>

        <Col md={6} className="mt-[12px] lg:mt-[0px] md:mt-[0px]">
          <p className="text-xs text-gray-400">Cvv Code*</p>
          <Form.Control
            placeholder="234"
            type="number"
            id=""
            aria-describedby=""
            className=" mt-2"
          />
        </Col>
      </Row>

      <div className="flex mt-3">
        <Form>
          <Form.Check />
        </Form>

        <p className="text-sm pl-2 text-defaultText">Set as default payment</p>
      </div>
    </div>
  );
};

export default CreditCard;
