import React from "react";
import ProSideBar from "../../components/sidebar/sidebar";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import iconhuman from "../../images/iconhuman.png";
import iconprinter from "../../images/iconprinter.png";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import ReportContent from "./report_content";
import CustomNavbar from "../../components/navbar/customNavbar";

const Reports: React.FC = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* sidebar */}
      <ProSideBar userGroupIcon="reportActive" reportActive="active" />

      <div className="flex flex-col flex-1 w-full overflow-x-hidden h-full ">
        <CustomNavbar />

        {/* maincontent */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Row className="px-3">
            <Col md={8} lg={9}>
              <Row>
                <Col md={12}>
                  <span className="text-[25px] text-defaultText font-bold ">
                    Reports
                  </span>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mt-3">
                  <div className="w-full px-4 py-4  bg-white rounded-2xl shadow-md">
                    <div className="flex">
                      <Dropdown>
                        <Dropdown.Toggle
                          as={Button}
                          id="dropdown-basic"
                          className="rounded-2xl bg-white text-black border boder-gray"
                        >
                          All grades
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#action1">
                            All grades
                          </Dropdown.Item>
                          <Dropdown.Item href="#action2">Option 2</Dropdown.Item>
                          <Dropdown.Item href="#action3">Option 3</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className=" relative pl-1">
                        <input
                          className="w-full  h-8 px-4 outline outline-2 outline-defaultBlue_20 focus:border-none rounded-xl"
                          placeholder="Search Student "
                        />
                        <div className="absolute bottom-3 left-auto right-0  ">
                          <MagnifyingGlassIcon className="h-5 w-5 " />
                        </div>
                      </div>
                    </div>
                    <ReportContent />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={4} xs={12} lg={3} >
              <div className="flex mt-3 ">
                <div className="  flex px-2 rounded  py-2  h-8 bg-white shadow-md ">
                  <img src={iconhuman} className="w-4 h-4" />
                  <span className="text-defaultBlue pl-2">Export</span>
                </div>
                <div className="pl-2">
                  <div className=" h-8 px-2 py-2 bg-white rounded shadow-md">
                    <img src={iconprinter} className="w-4 h-4" />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <p className="text-sm text-defaultText">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Reports;
