import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Iconspayment from "../../images/Iconspayment.png";
import Iconsmartphone from "../../images/Iconsmartphone.png";
import CreditCard from "./credit_card";
import MobileMoney from "./mobile_money";

interface CardStyle {
  border?: string;
  color?: string;
  background?: string;
}

const PaymentTabs: React.FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const inactivestylecard: CardStyle = {
    border: "#6666EA",
    color: "#201E5A",
  };
  const activestylecard: CardStyle = {
    background: "#9494F5",
    color: "white",
  };
  return (
    <div>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList className={"flex gap-4"}>
          <Tab>
            <div
              className="w-[120px] md:w-full flex px-3 py-1   rounded-2xl border border-defaultBlue  "
              style={tabIndex == 0 ? activestylecard : inactivestylecard}
            >
              <img src={Iconspayment} className="h-5 w-5" />
              <span className="pl-2">Credit Card</span>
            </div>
          </Tab>
          <Tab>
            <div
              className=" flex px-3 py-1  rounded-2xl border border-defaultBlue"
              style={tabIndex == 1 ? activestylecard : inactivestylecard}
            >
              <img src={Iconsmartphone} className="h-5 w-5" />
              <span className="pl-2">Mobile Money</span>
            </div>
          </Tab>
        </TabList>
        <TabPanel>
          <CreditCard />
        </TabPanel>
        <TabPanel>
          <MobileMoney />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default PaymentTabs;
