import React from "react";
import { Typography } from "../../components/typography/Typography";
import DataTable from "react-data-table-component";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import filter_icon from "../../images/filter_icon.png";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const StudentTable: React.FC = () => {
  const dummyData = [
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Mawuli Togbor",
      lastseen: "23 Feb,2022",
      studentid: "RCST478532",
      fees: "paid",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Mawuli Togbor",
      lastseen: "23 Feb,2022",
      studentid: "RCST478532",
      fees: "paid",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Mawuli Togbor",
      lastseen: "23 Feb,2022",
      studentid: "RCST478532",
      fees: "paid",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Mawuli Togbor",
      lastseen: "23 Feb,2022",
      studentid: "RCST478532",
      fees: "paid",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Mawuli Togbor",
      lastseen: "23 Feb,2022",
      studentid: "RCST478532",
      fees: "paid",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Mawuli Togbor",
      lastseen: "23 Feb,2022",
      studentid: "RCST478532",
      fees: "paid",
    },
    {
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      name: "Mawuli Togbor",
      lastseen: "23 Feb,2022",
      studentid: "RCST478532",
      fees: "paid",
    },
  ];
  const columns = [
    {
      name: "",
      cell: (row: any) => (
        <img src={row.image} alt="" className="rounded-full h-8 w-8" />
      ),
    },
    {
      name: <Typography.MediumText text="Name" xStyle="text-defaultText" />,
      cell: (row: any) => <span className="text-defaultBlue">{row.name} </span>,
    },
    {
      name: (
        <Typography.MediumText text="Last Seen" xStyle="text-defaultText" />
      ),
      cell: (row: any) => <span>{row.lastseen}</span>,
    },

    {
      name: (
        <Typography.MediumText text="Student ID" xStyle="text-defaultText" />
      ),
      cell: (row: any) => <span>{row.studentid}</span>,
    },
    {
      name: <Typography.MediumText text="Fees" xStyle="text-defaultText" />,
      cell: (row: any) => (
        <div className="bg-gray-100 h-6 w-[70px] rounded-2xl px-3  ">
          <span className="text-secondary-green   ">{row.fees}</span>{" "}
        </div>
      ),
    },
  ];

  return (
    <div className="w-full bg-white rounded-2xl shadow-md">
      <div className="px-2   ">
        <Row>
          <Col md={9}>
            <div className="mt-4 flex ">
              <Dropdown>
                <Dropdown.Toggle
                  as={Button}
                  id="dropdown-basic"
                  className="rounded-2xl bg-white text-black border boder-gray"
                >
                  All grades
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#action1">All grades</Dropdown.Item>
                  <Dropdown.Item href="#action2">Option 2</Dropdown.Item>
                  <Dropdown.Item href="#action3">Option 3</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <div className=" relative pl-1">
                <input
                  className="w-full  h-8 px-4 outline outline-2 outline-defaultBlue_20 focus:border-none rounded-xl"
                  placeholder="Search Student "
                />
                <div className="absolute bottom-3 left-auto right-0  ">
                  <MagnifyingGlassIcon className="h-5 w-5 " />
                </div>
              </div>
            </div>
          </Col>

          <Col md={12} sm={12} lg={3} className="mt-4 ">
            <div className="flex  items-center">
              <img src={filter_icon} className="h-6 w-6 " />

              <div className="pl-2">
                <Dropdown>
                  <Dropdown.Toggle
                    as={Button}
                    id="dropdown-basic"
                    className="rounded-2xl bg-white text-black border boder-gray w-[100px]"
                  >
                    Paid
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#action1">Paid</Dropdown.Item>
                    <Dropdown.Item href="#action2">Option 2</Dropdown.Item>
                    <Dropdown.Item href="#action3">Option 3</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="mt-4">
        <DataTable
          columns={columns}
          selectableRows
          data={dummyData}
          //  progressPending={pending}
          pagination
        />
      </div>
    </div>
  );
};

export default StudentTable;
