import React from 'react'
import { Select } from 'antd'


interface theProps {
    value?: any
    onChange?: (data: any) => void
}


const ProgramTypeSelector = ({ value, onChange }: theProps) => {


    return (
        <>
            <Select
                className='h-[44px]'
                value={value}
                options={[
                    { label: "Integration", value: "INTEGRATION" },
                    { label: "Co-Curricular", value: "CO_CURRICULAR" }
                ]}
                onChange={onChange}
                placeholder="Select"
                allowClear
            />
        </>
    )
}

export default ProgramTypeSelector
