import React, { useState } from 'react'
import { Form, Input, Select, Spin } from 'antd';
import ProgramTypeSelector from '../../../components/shared/programTypeSelector';
import { regionsList } from '../../../components/shared/regionsList';
import { useForm } from 'antd/es/form/Form';
import { useGeneralContext } from '../../../context/generalContext';
import BaseService from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';
import ShowAlert from '../../../components/alerts/all_alerts';


const SchoolInformation = () => {

    const { allMySchools, getMySchools } = useGeneralContext()
    const [isBusy, setIsBusy] = useState(false)
    const [form] = useForm()

    // submit form
    const onFormFinished = async (values: any) => {

        let school_info: any = {
            "_id": allMySchools?._id,
            "address": {
                "placeName": values?.placeName,
                "region": values?.region,
                "city": values?.city
            },
            "socials": {
                "facebook": values?.facebook,
                "youtube": values?.youtube,
                "instagram": values?.instagram,
                "tiktok": values?.tiktok,
                "linkedIn": values?.linkedIn,
                "x": values?.x,
            },
            "status": "DRAFT",
            "schoolInformation": {
                "programType": values?.programType,
                "leanerPopulation": parseInt(values?.leanerPopulation),
                "schoolName": values?.schoolName
            }
        }

        if (!school_info?.socials?.facebook) {
            delete school_info?.socials?.facebook
        }
        if (!school_info?.socials?.youtube) {
            delete school_info?.socials?.youtube
        }
        if (!school_info?.socials?.instagram) {
            delete school_info?.socials?.instagram
        }
        if (!school_info?.socials?.tiktok) {
            delete school_info?.socials?.tiktok
        }
        if (!school_info?.socials?.x) {
            delete school_info?.socials?.x
        }
        if (!school_info?.socials?.linkedIn) {
            delete school_info?.socials?.linkedIn
        }
        if (Object.keys(school_info?.socials).length === 0) {
            delete school_info?.socials
        }


        console.log('schoolInfo:', school_info);

        setIsBusy(true)

        try {
            const response = await BaseService.put_api(global_variables.update_school, school_info)
            console.log("schoolRes:", response?.data)
            form.resetFields()
            setIsBusy(false)
            getMySchools()
        } catch (error: any) {
            console.log("schoolErr:", error)
            setIsBusy(false)
            ShowAlert.error_alert("Error", error?.response?.data?.error || "An unknown error occured. Please retry", "Retry")
        }

    };



    return (
        <>
            <p className='text-[21px] font-bold text-defaultText'>School Information</p>

            <hr className='my-[20px] outline-[2px] outline-[#F0EFF8]' />

            <Spin spinning={isBusy}>
                <Form
                    name="principalForm"
                    onFinish={onFormFinished}
                    layout="vertical"
                >
                    <Form.Item
                        label="School Name"
                        name="schoolName"
                        rules={[{ required: true, message: 'Please input your school name!' }]}
                    >
                        <Input className='h-[44px]' placeholder='Saint Johns International' />
                    </Form.Item>

                    <Form.Item
                        label="Learner Population"
                        name="leanerPopulation"
                        rules={[{ required: true, message: 'Please fill out this field!' }]}
                    >
                        <Input type='number' className='h-[44px]' placeholder='500' />
                    </Form.Item>

                    <Form.Item
                        label="Program Type"
                        name="programType"
                        rules={[{ required: true, message: 'Please choose your program type!' }]}
                    >
                        <ProgramTypeSelector onChange={(selected) => console.log(selected)} />
                    </Form.Item>

                    <div className="pt-[20px]">
                        <p className='text-[16px] font-[700] text-defaultText'>Campus Address</p>

                        <Form.Item
                            className='mt-[20px]'
                            label="Physical Address"
                            name="placeName"
                            rules={[{ required: true, message: 'Please enter your school address' }]}
                        >
                            <Input className='h-[44px]' placeholder='Cantoments 4A street' />
                        </Form.Item>

                        <Form.Item
                            label="Region"
                            name="region"
                            rules={[{ required: true, message: 'Please enter region' }]}
                        >
                            <Select className='h-[44px]' placeholder='Choose region' options={regionsList} onChange={(selected) => console.log(selected)} allowClear showSearch />
                        </Form.Item>

                        <Form.Item
                            label="City / Area"
                            name="city"
                            rules={[{ required: true, message: 'Please enter city or area' }]}
                        >
                            <Input className='h-[44px]' placeholder='Cantoments' />
                        </Form.Item>
                    </div>

                    <div className="pt-[20px]">
                        <p className='text-[16px] font-[700] text-defaultText'>Social Media <span className='font-[400] italic'>(Optional)</span></p>

                        <Form.Item
                            className='mt-[20px]'
                            label="Facebook"
                            name="facebook"
                        >
                            <Input className='h-[44px]' placeholder='www.facebook.com' />
                        </Form.Item>

                        <Form.Item
                            label="TikTok"
                            name="tiktok"
                        >
                            <Input className='h-[44px]' placeholder='www.tiktok.com' />
                        </Form.Item>

                        <Form.Item
                            label="Instagram"
                            name="instagram"
                        >
                            <Input className='h-[44px]' placeholder='www.instagram.com' />
                        </Form.Item>

                        <Form.Item
                            label="Youtube"
                            name="youtube"
                        >
                            <Input className='h-[44px]' placeholder='www.youtube.com' />
                        </Form.Item>

                        <Form.Item
                            label="LinkedIn"
                            name="linkedIn"
                        >
                            <Input className='h-[44px]' placeholder='www.linkedIn.com' />
                        </Form.Item>

                        <Form.Item
                            label="Twitter (X)"
                            name="x"
                        >
                            <Input className='h-[44px]' placeholder='www.twitter.com' />
                        </Form.Item>
                    </div>


                    <Form.Item className='mt-[40px]'>
                        <button type='submit' className='h-[50px] w-full rounded-[40px] bg-defaultBlue text-white'>
                            <p className='text-[16px] font-[700]'>Save</p>
                        </button>
                    </Form.Item>
                </Form>
            </Spin>
        </>
    )
}

export default SchoolInformation
