const route = "/robocentre";

export const global_variables = {
  //schools
  create_school: `${route}/roboschool/onboarding/school`,
  update_school: `${route}/roboschool/onboarding/school`,
  get_my_school: `${route}/roboschool/onboarding/school/me`,
  get_program_types: `${route}/educational-type`,

  //academic term
  create_academic_term: `${route}/roboschool/onboarding/academic-term`,
  update_academic_term: `${route}/roboschool/onboarding/academic-term`,
  get_all_academic_terms: `${route}/roboschool/onboarding/academic-term/:schoolId`,
  get_assessment_types: `${route}/roboschool/onboarding/assesments`,
  get_all_curriculums: `${route}/curriculum`,
};
