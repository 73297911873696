import { useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import {
  Bars3Icon,
  CalendarDaysIcon,
  Cog6ToothIcon,
  FolderIcon,
  HomeIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import logoImg from "../../images/logo.png";
import { LuAlignHorizontalJustifyStart } from "react-icons/lu";
import { useGeneralContext } from "../../context/generalContext";


const ProSideBar = (props: any) => {

  const { isCollapsed, setIsCollapsed } = useGeneralContext()

  const [toggleSidebar, settoggleSidebar] = useState<boolean>(false);

  const handleSidebar = (): void => {
    if (toggleSidebar) {
      settoggleSidebar(false);
    } else {
      settoggleSidebar(true);
    }
  };

  const menuIconClick = () => {
    setIsCollapsed(!isCollapsed);
    // collapseSidebar();
  };

  return (
    <>
      <div className="absolute top-0 z-50 left-3">
        <Bars3Icon className="h-6 w-6  mt-4  lg:hidden text-defaultBlue " onClick={() => handleSidebar()} />
      </div>
      <div id="mySidebar" className={`h-[100vh] bg-white lg:block overflow-y-auto overflow-x-hidden ${toggleSidebar ? "block absolute z-50" : "hidden"}`}>
        <Sidebar
          collapsed={isCollapsed}

        >
          <Menu className="relative">
            {/* logo */}
            <div className="flex gap-[24px] ml-6 mt-[15px]">
              <Link
                className={isCollapsed ? "" : "hidden lg:block"}
                to="#"
                onClick={menuIconClick}
              >
                <Bars3Icon className="w-[30px] h-[30px] text-defaultBlue" />
              </Link>
              <div>
                {isCollapsed ? "" : <Image src={logoImg} alt="logo" fluid />}
              </div>

            </div>
            <div className="absolute right-0 top-0 lg:hidden mx-3  flex justify-end">
              <XCircleIcon className="h-5 w-5 text-defaultBlue " onClick={() => settoggleSidebar(false)} />
            </div>
            <br />

            {/* menus */}
            <MenuItem
              icon={
                <LuAlignHorizontalJustifyStart
                  className={`w-[18px] h-[18px] text-secondary_bg text-defaultBlue_60 ${props.dashIcon1}`}
                />
              }
              className={props.dashboardActive1}
              component={<Link to="/" />}
            >
              <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                Get Started
              </p>
            </MenuItem>
            <MenuItem
              icon={
                <HomeIcon
                  className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${props.dashIcon}`}
                />
              }
              className={props.dashboardActive}
              component={<Link to="/dashboard" />}
            >
              <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                Dashboard
              </p>
            </MenuItem>

            <MenuItem
              icon={
                <CalendarDaysIcon
                  className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${props.calenIcon}`}
                />
              }
              className={props.calendarActive}
              component={<Link to="/calendar" />}
            >
              <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                Calendar
              </p>
            </MenuItem>

            <MenuItem
              icon={
                <UserGroupIcon
                  className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${props.yeargroupIcon}`}
                />
              }
              className={props.yeargroupActive}
              component={<Link to="/year_group" />}
            >
              <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                Year Group
              </p>
            </MenuItem>

            <MenuItem
              icon={
                <UserGroupIcon
                  className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${props.classesIcon}`}
                />
              }
              className={props.classesGroupActive}
              component={<Link to="/classes" />}
            >
              <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                Classes
              </p>
            </MenuItem>

            <MenuItem
              icon={
                <UserGroupIcon
                  className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${props.myStudentIcon}`}
                />
              }
              className={props.myStudentActive}
              component={<Link to="/students" />}
            >
              <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                Students
              </p>
            </MenuItem>

            <hr className="text-defaultBlue_40 my-[20px] mx-[27px]" />

            <MenuItem
              icon={
                <CalendarDaysIcon
                  className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${props.academicIcon}`}
                />
              }
              className={props.academicActive}
              component={<Link to="/academic_year" />}
            >
              <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                Academic Year
              </p>
            </MenuItem>
            <MenuItem
              icon={
                <PresentationChartLineIcon
                  className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${props.reportIcon}`}
                />
              }
              className={props.reportActive}
              component={<Link to="/reports" />}
            >
              <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                Report
              </p>
            </MenuItem>
            <MenuItem
              icon={
                <PresentationChartLineIcon
                  className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${props.paymentIcon}`}
                />
              }
              className={props.paymentActive}
              component={<Link to="/payments" />}
            >
              <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                Payments
              </p>
            </MenuItem>

            <hr className="text-defaultBlue_40 mt-[20px] mx-[27px]" />

            <MenuItem
              icon={
                <Cog6ToothIcon
                  className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${props.settingsIcon}`}
                />
              }
              className={`mt-[20px] ${props.settingsActive}`}
              component={<Link to="/settings" />}
            >
              <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                Settings
              </p>
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </>
  );
};

export default ProSideBar;
