import React from 'react'
import ProSideBar from '../../components/sidebar/sidebar'
import { Col, Row } from 'react-bootstrap'
import StudentTable from './students_table'
import iconhuman from '../../images/iconhuman.png';
import iconprinter from '../../images/iconprinter.png'
import CustomNavbar from '../../components/navbar/customNavbar';

const Students: React.FC = () => {
    return (
        <div className='flex flex-row overflow-hidden h-[100vh] w-full'>
            {/* sidebar */}
            <ProSideBar userGroupIcon="myStudentActive" myStudentActive="active" />

            <div className="flex flex-col flex-1 w-full overflow-x-hidden h-full ">
                <CustomNavbar />

                {/* maincontent */}
                <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
                    <Row className='px-3'>
                        <Col md={8} lg={9}>
                            <Row>
                                <Col md={12} lg={12}>
                                    <span className='text-[25px] text-defaultText font-bold '>Students</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} lg={12} className='mt-3'>
                                    <StudentTable />
                                </Col>
                            </Row>
                        </Col>

                        <Col md={4} lg={3} className='mt-3'>
                            <div className='flex'>
                                <div className='flex px-2 rounded  py-2 w-[100px] h-8 bg-white shadow-md'>
                                    <img src={iconhuman} className='w-4 h-4' />
                                    <span className='text-defaultBlue pl-2'>Export</span>
                                </div>
                                <div className='pl-2'>
                                    <div className='w-[30px] h-8 px-2 py-2 bg-white rounded shadow-md'>
                                        <img src={iconprinter} className='w-4 h-4' />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <p className='text-sm text-defaultText'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat</p>
                            </div>
                        </Col>


                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Students