import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import BaseService from '../helpers/baseServices';
import { global_variables } from '../helpers/globalVariables';
import { getCookie } from '../helpers/utils';
import { COOKIES_USER_DATA } from '../helpers/constants';
import ShowAlert from '../components/alerts/all_alerts';


// Create the context
const GeneralContext = createContext<any>(null);

// Create a custom hook for easy access
export const useGeneralContext = () => useContext(GeneralContext);

interface ApiProviderProps {
    children: ReactNode;
}

export const GeneralContextProvider: React.FC<ApiProviderProps> = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useState<string>('');
    const [allMySchools, setAllMySchools] = useState<any>(null)
    const [userData, setUserData] = useState<any>(null)
    const [onboardStep, setOnboardStep] = useState(1)
    const [isLoading, setIsLoading] = useState(true)

    const getMySchools = async () => {
        setIsLoading(true)

        try {
            const response = await BaseService.get_api(`${global_variables.get_my_school}`)
            console.log("---mySchoolRes:", response?.data)

            //check stage
            if (response?.data?.payload?.principal && Object.keys(response?.data?.payload?.principal).length > 0) {
                setOnboardStep(1);

                if (response?.data?.payload?.schoolInformation && Object.keys(response?.data?.payload?.schoolInformation).length > 0 &&
                    response?.data?.payload?.address && Object.keys(response?.data?.payload?.address).length > 0) {
                    setOnboardStep(2);

                    if (response?.data?.payload?.curriculumLevelIds && response.data.payload?.curriculumLevelIds.length > 0) {
                        setOnboardStep(3);
                    }
                }
            }
            else {
                setOnboardStep(0);
            }
            setAllMySchools(response?.data?.payload);
            setIsLoading(false)

        } catch (error: any) {
            console.log("mySchoolErr:", error)
            ShowAlert.error_alert("Error", error?.response?.data?.error || "An unknown error occured. Retry", "Retry")
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
        }
    };

    //onload
    useEffect(() => {
        getMySchools()
        const user_data = JSON.parse(getCookie(COOKIES_USER_DATA))
        setUserData(user_data)
        console.log(user_data)
    }, [])

    return (
        <GeneralContext.Provider value={{ isLoading, allMySchools, getMySchools, userData, onboardStep, setOnboardStep, isCollapsed, setIsCollapsed }}>
            {children}
        </GeneralContext.Provider>
    );
};
