import React from 'react'
import SchoolName from './SchoolName'
import NavSearchBar from './NavSearchBar'
import ProfilePic from './ProfilePic'

const CustomNavbar = () => {
    return (
        <>
            <nav className="px-[16px] py-[20px] z-10 w-full">
                <div className="flex justify-between">
                    <div className="hidden lg:block">
                        <SchoolName />
                    </div>

                    <div className="flex gap-[20px] items-center">
                        <div className="hidden md:block step3">
                            <NavSearchBar />
                        </div>

                        <ProfilePic />
                    </div>
                </div>
            </nav>
        </>
    )
}

export default CustomNavbar
