import React from "react";
import { Col, Row } from "react-bootstrap";

const ProfileSettings:React.FC = () => {
  return (
    <div className="py-4">
      <span className=" text-lg font-bold text-defaultText ">Profile</span>
      <div className="mt-3">
        <div className="px-3 py-3  w-full rounded-xl bg-white shadow-md md:h-auto ">
          <span className="text-sm font-bold text-defaultText">
            School Detail
          </span>
          <div className="mt-3">
            <p className="text-xs">Description</p>
            <div className="mt-3">
            <span>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At
            </span>
            </div>
            <Row className="mt-6">
                <Col md={4}>
                    <div className="flex flex-col">
                      <p className="text-xs">Email</p>
                      <span className="text-sm">sdfghfjfg</span>

                    </div>
                </Col>
                <Col md={4}>
                    <div className="flex flex-col">
                      <p className="text-xs">Preferred Name</p>
                      <span className="text-sm">Lorem ipsum</span>

                    </div>
                </Col>
                <Col md={4}>
                    <div className="flex flex-col">
                      <p className="text-xs">Contact 1</p>
                      <span className="text-sm">+2331 234 56</span>

                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={4}>
                    <div className="flex flex-col">
                      <p className="text-xs">Email</p>
                      <span className="text-sm">sdfghfjfg</span>

                    </div>
                </Col>
                <Col md={4}>
                    <div className="flex flex-col">
                      <p className="text-xs">Preferred Name</p>
                      <span className="text-sm">Lorem ipsum</span>

                    </div>
                </Col>
                <Col md={4}>
                    <div className="flex flex-col">
                      <p className="text-xs">Contact 1</p>
                      <span className="text-sm">+2331 234 56</span>

                    </div>
                </Col>
            </Row>
            <hr className="mt-4"></hr>
            <div className="mt-3">
            <span className="text-sm font-bold text-defaultText">
            Contact Details
          </span>
            </div>
            <Row className="mt-6">
                <Col md={4}>
                    <div className="flex flex-col">
                      <p className="text-xs">Contact 1</p>
                      <span className="text-sm">+2331 234 56</span>

                    </div>
                </Col>
                <Col md={4}>
                    <div className="flex flex-col">
                      <p className="text-xs">Country</p>
                      <span className="text-sm">Ghana</span>

                    </div>
                </Col>
                <Col md={4}>
                    <div className="flex flex-col">
                      <p className="text-xs">State</p>
                      <span className="text-sm">Kwaku</span>

                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={4}>
                    <div className="flex flex-col">
                      <p className="text-xs">Contact 2</p>
                      <span className="text-sm">+2331 234 56</span>

                    </div>
                </Col>
                <Col md={4}>
                    <div className="flex flex-col">
                      <p className="text-xs">City</p>
                      <span className="text-sm">John</span>

                    </div>
                </Col>
                <Col md={4}>
                    <div className="flex flex-col">
                      <p className="text-xs">Address</p>
                      <span className="text-sm">Doe Street</span>

                    </div>
                </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
