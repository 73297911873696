import moment from 'moment';
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Typography } from '../../components/typography/Typography';
var theDate = require('current-date');


const SmallCalendar:React.FC = () => {

    const [value, setValue] = useState(new Date());

    const handleChange = (e:any) => {
        // console.log(e)
        setValue(e);
    }

    return (

        <div className='mb-[10px]'>
            <Typography.MediumText text={moment(theDate('full')).format('MMMM, YYYY')} xStyle='font-semibold mb-[8px]' />
            <Calendar
                onChange={handleChange}
                value={value}
                prevLabel={null}
                nextLabel={null}
                next2Label={null}
                prev2Label={null}
            />
        </div>
    )
}

export default SmallCalendar