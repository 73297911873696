import React from "react";
import { Form } from "react-bootstrap";

const NotificationSettings: React.FC = () => {
  return (
    <div>
      <div className="py-4 text-lg font-bold text-defaultText">
        Notification
      </div>
      <div className=" w-full h-[400px] bg-white shadow-md rounded-xl">
        <div className="px-4 py-4">
          <span className="font-bold text-defaultText">General</span>
        </div>
        <div className="flex justify-between w-full  bg-gray-200 ">
          <div className="px-4 flex flex-col">
            <span>Popup Notification</span>
            <p className="text-xs">
              Enable or disable push notifications on all mobile devices.
            </p>
          </div>
          <div className="mt-3 pr-6">
            <Form>
              <Form.Check type="switch" id="custom-switch" />
            </Form>
          </div>
        </div>
        <div className="mt-1">
          <div className="flex justify-between w-full  bg-gray-200 ">
            <div className="px-4 flex flex-col">
              <span>Popup Notifications</span>
              <p className="text-xs">
                Enable or disable push notifications on all mobile devices.
              </p>
            </div>
            <div className="mt-3 pr-6">
              <Form>
                <Form.Check type="switch" id="custom-switch" />
              </Form>
            </div>
          </div>
        </div>
        <div className="px-4 py-3">
          <span className="font-bold text-defaultText">Courses</span>
        </div>
        <div className="mt-1">
          <div className="flex justify-between w-full  bg-gray-200 ">
            <div className="px-4 flex flex-col">
              <span>Course Coupon Redeption</span>
              <p className="text-xs">
                Notifications when Course coupon is redeemed
              </p>
            </div>
            <div className="mt-3 pr-6">
              <Form>
                <Form.Check type="switch" id="custom-switch" />
              </Form>
            </div>
          </div>
        </div>
        <div className="mt-1">
          <div className="flex justify-between w-full  bg-gray-200 ">
            <div className="px-4 flex flex-col">
              <span>Complete Course</span>
              <p className="text-xs">
                Notifications when Students complete a course
              </p>
            </div>
            <div className="mt-3 pr-6">
              <Form>
                <Form.Check type="switch" id="custom-switch" />
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className=" mt-8  md:mt-4 gap-2 flex justify-end">
        <button className="text-red-500">Cancel</button>
        <button className=" bg-defaultBlue text-white h-[35px] px-3 rounded-2xl">
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default NotificationSettings;
