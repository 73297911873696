import React from "react";
import { Typography } from "../../components/typography/Typography";
import { Dropdown, Image } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";


const YearTable = () => {
  const dummyData = [
    {
      name: "Grade 4 -2023",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Early",
    },
    {
      name: "Grade 5-2023",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Late",
    },
    {
      name: "Grade 6-2023",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Late",
    },
    {
      name: "Grade 4-2023",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Late",
    },
  ];
  //Table columns
  const columns = [
    {
      name: <Typography.MediumText text="Name" xStyle="text-defaultBlue" />,
      cell: (row: any) => (
        <div className="flex justify-between items-center mt-[10px] border-[1px] border-defaultBlue_40 w-[600px] md:w-[600px] lg:w-full  rounded-[20px] px-[15px]  ">
          <Typography.MediumText
            text={row.name}
            xStyle="text-defaultBlue font-semibold"
          />
          <div className="flex gap-[15px]">
            {/* images */}
            {/* <div className="flex -space-x-1 overflow-hidden"> */}
            <div className=" flex ">
              <Image
                className="  inline-block object-cover h-6 w-6 rounded-full ring-2 ring-white"
                src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
                fluid
              />
              <Image
                className="inline-block object-cover h-6 w-6 rounded-full ring-2 ring-white"
                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
                fluid
              />
              <Image
                className="inline-block object-cover h-6 w-6 rounded-full ring-2 ring-white"
                src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                alt=""
                fluid
              />
            </div>
            <div className=" flex gap-[5px] items-center">
              <Typography.HeadingFour text="53" xStyle="text-secondary-green" />
              <Typography.MediumText text="Students" />
            </div>
            <div className=" flex gap-[5px] items-center">
              <Typography.HeadingFour text="6" xStyle="text-secondary-pink" />
              <Typography.MediumText text="units" />
            </div>
            <div className="flex gap-[5px] items-center">
              <Typography.HeadingFour text="4" xStyle="text-defaultYellow" />
              <Typography.MediumText text="Courses" />
            </div>
          </div>
          {/* <div className=" h-8"></div> */}
          <div className="flex gap-[15px] items-center text-defaultBlue border-[1.5px] pl-2 border-y-0 border-r-0 border-l-defaultBlue_40">
            {/* <ChatBubbleLeftEllipsisIcon className="w-5 h-5 cursor-pointer" /> */}
            {/* <UserCircleIcon className="w-5 h-5 cursor-pointer" /> */}
            <Dropdown className="flex items-center relative">
              {/* <Dropdown.Toggle as={CustomToggle} />  */}

              <Dropdown.Menu className="absolute right-0 bottom-0 z-10">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className="  w-full  bg-white rounded-2xl shadow-md">
      <div className="relative py-4 px-4">
        <input
          className="w-[270px]  h-8 px-5  outline outline-2 outline-defaultBlue_20 focus:border-none rounded-xl"
          placeholder="Search "
        />
        <div className="absolute bottom-7 left-8  ">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 " />
        </div>

      </div>

      <div className="mt-2 ">
        <DataTable
          columns={columns}
          selectableRows
          data={dummyData}
          pagination
        />
      </div>
    </div>
  );
};

// const CustomToggle = React.forwardRef((props:{ children:any, onClick?:()=>void }, ref) => (

//     <EllipsisVerticalIcon
//       className="h-6 w-6 text-defaultBlue font-semibold cursor-pointer"
//       onClick={onClick}
//     />
//   ));

export default YearTable;
