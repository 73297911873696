import React from 'react'
import PaymentTabs from './payment_tabs'

const PaymentSettings:React.FC = () => {
  return (
    <div className='py-4'>
    <span className=' text-lg font-bold text-defaultText'>Payment</span>
    <div className='mt-3'>
    <div className='px-3 py-3 w-full  rounded-xl bg-white shadow-md'>
      <span className='font-bold'>Select Payment Method</span>
      <div className='mt-3'>
        <PaymentTabs/>
      </div>
    </div>
    </div>
    </div>
  )
}

export default PaymentSettings