import React, { useState } from 'react'
import { GoArrowLeft } from "react-icons/go";
import { Checkbox, Progress, Spin } from 'antd'
import PrinicpalInformation from './steps/prinicpalInformation'
import SchoolInformation from './steps/schoolInformation'
import AcademicsTab from './steps/academicsTab';
import { useNavigate } from 'react-router-dom';
import { useGeneralContext } from '../../context/generalContext';


const ActivateAccount = () => {

    const navigate = useNavigate()
    const { onboardStep, isLoading } = useGeneralContext()

    //theSteps
    const theSteps = [
        "Principal Information",
        "School Information",
        "Academics"
    ]

    //stepComponents
    const stepComponents = [
        <PrinicpalInformation />,
        <SchoolInformation />,
        <AcademicsTab />
    ]

    return (
        <>
            <Spin spinning={isLoading}>
                <div className="h-screen bg-defaultBlue_5 w-full overflow-y-hidden">
                    <div className="h-full w-full px-[20px] lg:px-[200px] overflow-y-auto py-[60px]">
                        <div className="flex w-full gap-[30px] items-center">
                            <button className='flex gap-[3px] items-center text-defaultText px-[20px] py-[8px] rounded-full border-[1px] border-defaultText' onClick={() => navigate("/")}>
                                <GoArrowLeft className='h-[20px] w-[20px]' />
                                <p>Back</p>
                            </button>
                            <p className='text-[32px] font-bold text-defaultText'>Activate your account</p>
                        </div>

                        <div className="flex justify-center w-full">
                            <div className="grid grid-cols-5 gap-[40px] mt-[20px] w-full">
                                {/* left */}
                                <div className="col-span-2 w-full">
                                    <div className="w-full bg-white shadow-md rounded-[20px] py-[40px]">
                                        <div className="flex flex-col justify-center gap-y-[10px]">
                                            {
                                                theSteps.map((step, i) => (
                                                    <button key={i} className={`${onboardStep === i ? 'border-l-[5px] border-l-defaultBlue font-[500] bg-defaultBlue_5' : 'border-l-[5px] border-l-transparent'} px-[30px] py-[15px] flex gap-[10px] items-center text-defaultText disabled:cursor-not-allowed disabled:text-gray-400`} disabled={onboardStep !== i}>
                                                        <div>
                                                            <Checkbox checked={onboardStep > i} />
                                                        </div>
                                                        <p>{step}</p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                    </div>

                                    <div className="mt-[20px] w-full">
                                        <Progress percent={onboardStep === 0 ? 25 : onboardStep === 1 ? 55 : onboardStep === 2 ? 80 : 100} showInfo={false} strokeColor="#0DBC76" />
                                        <p className='text-center text-defaultText'>
                                            {onboardStep === 0 ? "0/3 Complete" : onboardStep === 1 ? "1/3 Complete" : onboardStep === 2 ? "2/3 Complete" : "3/3 Complete"}
                                        </p>
                                    </div>
                                </div>

                                {/* right */}
                                <div className="w-full col-span-3">
                                    <div className="w-full bg-white shadow-md rounded-[20px] py-[30px] px-[50px]">
                                        {
                                            stepComponents[onboardStep]
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default ActivateAccount
