import React from "react";

const HeadingOne = (props: { text:string, xStyle?:any }) => {
  const {text,xStyle} = props
  return <h1 className={`text-[56px] font-semibold ${xStyle}`}>{text}</h1>;
};

const HeadingTwo = (props: { text:string, xStyle?:any }) => {
  const {text,xStyle} = props
  return <h2 className={`text-[42px] font-semibold ${xStyle}`}>{text}</h2>;
};

const HeadingThree = (props: { text:string, xStyle?:any }) => {
  const {text,xStyle} = props
  return <h2 className={`text-[28px] font-semibold ${xStyle}`}>{text}</h2>;
};

const HeadingFour = (props: { text:string, xStyle?:any }) => {
  const {text,xStyle} = props
  return (
    <h2 className={`md:text-[21px] text-[18px] font-semibold ${xStyle}`}>
      {text}
    </h2>
  );
};

const RegularText = (props: { text:string, xStyle?:any }) => {
  const {text,xStyle} = props
  return <p className={`text-[21px] ${xStyle}`}>{text}</p>;
};

const MediumText = (props: { text:string, xStyle?:any }) => {
  const {text,xStyle} = props
  return <p className={`md:text-[16px] text-[14px] ${xStyle}`}>{text}</p>;
};

const SmallText = (props: { text:string, xStyle?:any }) => {
  const {text,xStyle} = props
  return <p className={`text-[13px] ${xStyle}`}>{text}</p>;
};

const ExtraSmallText = (props: { text:string, xStyle?:any }) => {
  const {text,xStyle} = props
  return <p className={`text-[10px] ${xStyle}`}>{text}</p>;
};

export const Typography = {
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  HeadingFour,
  RegularText,
  MediumText,
  SmallText,
  ExtraSmallText,
};
