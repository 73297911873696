import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import mtnmoney from "../../images/mtnmoney.png";
import voda from "../../images/voda.png";
import Mtn from "./mtn";
import Voda from "./airtel";

const MobileTabs: React.FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <div>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList className={"flex gap-4 mt-2"}>
          <Tab>
            <div
              className={`border-2 w-[100px] h-[35px] rounded px-3 ${
                tabIndex === 0 ? "border-blue-500" : "border-gray-300"
              }`}
            >
              <img src={mtnmoney} alt="mtn" className="w-10 h-7" />
            </div>
          </Tab>
          <Tab>
            <div
              className={`border-2 w-[100px] h-[35px] rounded ${
                tabIndex === 1 ? "border-blue-500" : "border-gray-300"
              }`}
            >
              <img src={voda} alt="Voda" className="w-full h-8" />
            </div>
          </Tab>
        </TabList>
        <TabPanel>
          <Mtn />
        </TabPanel>
        <TabPanel>
          <Voda />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default MobileTabs;
