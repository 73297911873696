import React from "react";
import ProSideBar from "../../components/sidebar/sidebar";
import { Cards } from "../../components/cards/cards";
import Recentenrollment from "./recentenrollment";
import SmallCalendar from "./smallcalender";
import { Col, Row } from "react-bootstrap";
import iconclock from "../../images/iconclock.png";
import CustomNavbar from "../../components/navbar/customNavbar";

const Dashboard: React.FC = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* sidebar */}
      <ProSideBar dashIcon="dashIconActive" dashboardActive="active" />
      {/* navbar */}
      <div className="flex flex-col flex-1 w-full overflow-x-hidden h-full ">
        <CustomNavbar />
        {/* maincontent */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <div className="px-8 ">
            <div className=" mt-3">
              <span className="text-[21px] font-bold">Dashboard</span>
            </div>

            <Row>
              <Col sm={12} md={8} lg={9}>
                <Row>
                  <Col sm={12} md={12} lg={8}>
                    <Cards.WelcomeCard />
                  </Col>
                  <Col sm={12} md={12} lg={4} className="mt-[30px] md:mt-[20px] lg:mt-[0px]">
                    <Cards.EarningCard />
                  </Col>
                </Row>
                <Row className="mt-7">
                  <Col sm={12} md={12} lg={4} className="">
                    <Cards.TotalCard />
                  </Col>
                  <Col sm={12} md={12} lg={4} className="mt-[12px] md:mt-[10px] lg:mt-[0px]   ">
                    <Cards.ClassCard />
                  </Col>
                  <Col sm={12} md={12} lg={4} className="mt-[12px] md:mt-[10px] lg:mt-[0px] ">
                    <Cards.TotalLearningCard />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={12} lg={12}>
                    <Cards.RevenuePerformance />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={12} lg={12} className="flex justify-between ">
                    <span className="font-bold text-defaultText  ">
                      Recent Enrollment
                    </span>
                    <span className=" text-defaultBlue font-bold">
                      see all
                    </span>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col md={12} lg={12}>
                    <Recentenrollment />
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={4} lg={3} className="mt-[20px] md:mt-[0px]">
                <SmallCalendar />
                <hr></hr>
                <div className="mt-2">
                  <span className="font-bold">Upcoming Events</span>
                </div>
                <div className="flex">
                  <div className="h-10 w-9 bg-gray-300 rounded">
                    <div className="h-5 w-9 bg-defaultBlue rounded px-2">
                      <span className="text-xs text-white">Feb</span>
                    </div>

                    <span className="px-2 text-sm text-defaultBlue">24</span>
                  </div>
                  <div className="flex flex-col px-2">
                    <span>Robocode Hour</span>
                    <div className="flex ">
                      <div className="h-5 w-12  px-2  rounded-2xl bg-orange-500">
                        <span className="text-xs text-white">Event</span>
                      </div>
                      <div className="pl-1">
                        <img src={iconclock} className="w-3 h-3" />
                      </div>

                      <span className="text-xs pl-1">8:00AM-12:30PM</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
