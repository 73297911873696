import React from "react";
import { Col, Row } from "react-bootstrap";
import iconadobe from "../../images/iconadobe.png";

const ReportContent:React.FC = () => {
  return (
    <div className="mt-3  px-1">
      <span className="text-sm font-bold">Nov.2022 to Sept 2023</span>
      <Row className="mt-3 px-4">
        <Col md={4}>
          <span>Tilte</span>
        </Col>
        <Col md={4}>
          <span>Term</span>
        </Col>
        <Col md={4}>
          <span>Date Generated</span>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="w-full h-auto border py-1 border-gray-400 rounded-md">
            <Row className="px-4 ">
              <Col md={4} className="py-3 ">
                <div className="flex gap-1">
                  <img src={iconadobe} className="" />
                  <p className="text-defaultBlue text-sm">Term report 2</p>
                </div>
              </Col>

              <Col md={4} className="mt-1  border-x ">
                <div className="flex flex-col">
                  <span>Term 2</span>
                  <p className="text-xs">Nov,2022 to Sept 2023</p>
                </div>
              </Col>
              <Col md={4} className="py-3">
                <span>34/03/2022</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12}>
          <div className="w-full h-auto border py-1 border-gray-400 rounded-md">
            <Row className="px-4 ">
              <Col md={4} className="py-3 ">
                <div className="flex gap-1">
                  <img src={iconadobe} />
                  <p className="text-defaultBlue text-sm cursor-pointer">
                    Term report 1
                  </p>
                </div>
              </Col>

              <Col md={4} className="mt-1  border-x ">
                <div className="flex flex-col">
                  <span>Term 2</span>
                  <p className="text-xs">Nov,2022 to Sept 2023</p>
                </div>
              </Col>
              <Col md={4} className="py-3">
                <span>34/03/2022</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ReportContent;
