import React from "react";
import ProSideBar from "../../components/sidebar/sidebar";
import { Col, Row } from "react-bootstrap";
import CustomNavbar from "../../components/navbar/customNavbar";

const AcademicYear = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* sidebar */}
      <ProSideBar userGroupIcon="academicActive" academicActive="active" />

      <div className="flex flex-col flex-1 w-full overflow-x-hidden h-full ">
        {/* navbar */}
        <CustomNavbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Row className="px-3">
            <Col md={9}>
              <Row>
                <Col md={12}>
                  <span className="text-[25px] text-defaultText font-bold ">
                    Academic Year
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <span className="text-sm">Nov.2022-Jan 2024</span>
                </Col>
              </Row>
              <Row className="mt-3 ">
                <Col md={7} sm={12} >
                  <div className="py-4 px-4 bg-defaultBlue h-[100px] rounded-l-2xl">
                    <div className="flex gap-2   items-center">
                      <span className="text-white">Term 1</span>
                      <div className="w-14 rounded-2xl h-5 px-2 text-center  bg-secondary-orange">
                        <span className="text-xs text-white">current</span>
                      </div>
                    </div>
                    <span className="text-xs text-white">
                      Nov,2022 - Sept 2023
                    </span>
                  </div>
                </Col>
                <Col md={5} sm={12} className="mt-[10px] md:mt-[0px]">
                  <div className="  bg-secondary-green bg-opacity-[35%] h-[100px] rounded-r-2xl">
                    <div className="bg-secondary-green h-[100px] w-[100px]">
                      <div className="py-6">
                        <div className="text-center  ">
                          <span className="text-white">Term 3</span>
                        </div>
                        <div>
                          <p className="text-xs text-white">Sep-2022-Jan 2024</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={3} className="mt-6">
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                erat
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AcademicYear;
