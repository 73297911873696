import React from "react";
import ProSideBar from "../../components/sidebar/sidebar";
import { Col, Row } from "react-bootstrap";
import SettingTabs from "./settings_tabs";
import imageupload from "../../images/imageupload.png";
import CustomNavbar from "../../components/navbar/customNavbar";

const Settings: React.FC = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* sidebar */}
      <ProSideBar userGroupIcon="settingsActive" settingsActive="active" />

      <div className="flex flex-col flex-1 w-full overflow-x-hidden h-full ">
        <CustomNavbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Row className="px-3">
            <Col md={8} lg={9}>
              <Row>
                <Col md={12} className="py-3">
                  <span className="text-[25px] text-defaultText font-bold ">
                    Settings
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12}>
                  <SettingTabs />
                </Col>
              </Row>
            </Col>
            <Col md={4} lg={3} sm={12} className="mt-4 ">
              <div className="w-full md:w-[200px] lg:w-full border border-gray-300 rounded-xl ">
                <div className="px-5 py-3 ">
                  <div className="rounded-full h-[110px] w-[110px] bg-white  lg:h-[110px] lg:w-[110px] ">
                    <div className=" relative">
                      <img
                        src={imageupload}
                        className=" w-[100px] h-[100px]  rounded-full "
                      />
                    </div>
                  </div>
                  {/* <div className=' absolute  top-45 right-75'>
                        <img src={quickadd} />
                    </div> */}

                  <div className="mt-2">
                    <span className="font-bold text-defaultText">
                      Joana Doe LLC
                    </span>
                  </div>
                  <div>
                    <p className="text-sm text-defaultBlue">
                      Businesswebsite.com
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-defaultBlue">Email@gmail.com</p>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm">MBX775398xcyyu87</p>
                  </div>
                </div>
                <Row className="px-3">
                  <Col md={6} lg={6} xs={6}>
                    <span className="text-[32px] md:text-[20px] font-semibold text-defaultText">
                      2001
                    </span>
                    <p className="text-gray-400">Students</p>
                  </Col>
                  <Col md={6} lg={6} xs={6} className="border-x  ">
                    <span className="text-[32px] md:text-[20px] font-semibold text-defaultText">
                      34
                    </span>
                    <p className="text-gray-400">Classes</p>
                  </Col>
                </Row>
              </div>
              <div className="mt-2">
                <div className="w-full py-1 text-center  border-2 border-defaultBlue rounded-2xl">
                  <button className="text-defaultBlue">Change Password</button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Settings;
