import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ProfileSettings from "./profile_settings";
import AccountSettings from "./account_settings";
import NotificationSettings from "./notification_settings";
import PaymentSettings from "./payment_settings";
import SettingsProfile from "../../images/settingsprofile.png";
import accountsettings from "../../images/accontsettings.png";
import settingsnotification from "../../images/settingsnotification.png";
import paymentsettings from "../../images/paymentsettings.png";

interface settingsStyles {
  backgroundColor?: string;
  color?: string;
}

const SettingTabs: React.FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const activeTabfirstStyles: settingsStyles = {
    backgroundColor: " #5BDEC8",
    color: "white",
  };
  const inactiveTabfirstStyles: settingsStyles = {
    backgroundColor: "white",
    color: "black",
  };

  const activeTabsecondStyles: settingsStyles = {
    backgroundColor: "#28CBFE",
    color: "white",
  };
  const inactiveTabsecondStyles: settingsStyles = {
    backgroundColor: "white",
    color: "black",
  };
  const activeTabthirdStyles: settingsStyles = {
    backgroundColor: "#903EE4",
    color: "white",
  };

  const inactiveTabthirdStyles: settingsStyles = {
    backgroundColor: "white",
    color: "black",
  };

  const activeTabfourthStyles: settingsStyles = {
    backgroundColor: "#FF9EA7",
    color: "white",
  };

  const inactiveTabfourthStyles: settingsStyles = {
    backgroundColor: "white",
    color: "black",
  };

  return (
    <div className="">
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList className={"grid grid-cols-12gap-2   lg:flex gap-4  "}>
          <Tab>
            <div
              className="  w-[200px]  md:w-full flex px-3 gap-2 items-center py-2 rounded-2xl  shadow-md "
              style={
                tabIndex === 0 ? activeTabfirstStyles : inactiveTabfirstStyles
              }
            >
              <img src={SettingsProfile} className="h-8 w-8" />
              <span>Profile</span>
            </div>
          </Tab>

          <Tab>
            <div
              className=" w-[200px]  md:w-full flex px-3 gap-2 items-center py-2  rounded-2xl  shadow-md"
              style={
                tabIndex === 1 ? activeTabsecondStyles : inactiveTabsecondStyles
              }
            >
              <img src={accountsettings} className="h-8 w-8" />
              <span>Account</span>
            </div>
          </Tab>

          <Tab>
            <div
              className=" w-[200px] md:w-full flex px-3 gap-2 items-center py-2  h-[50px] rounded-2xl  shadow-md"
              style={
                tabIndex === 2 ? activeTabthirdStyles : inactiveTabthirdStyles
              }
            >
              <img src={settingsnotification} />
              <span>Notification</span>
            </div>
          </Tab>

          <Tab>
            <div
              className=" w-[200px] md:w-full flex px-3 gap-2 items-center py-2  h-[50px] rounded-2xl  shadow-md "
              style={
                tabIndex === 3 ? activeTabfourthStyles : inactiveTabfourthStyles
              }
            >
              <img src={paymentsettings} />
              <span>Payment</span>
            </div>
          </Tab>
        </TabList>

        <TabPanel>
          <ProfileSettings />
        </TabPanel>
        <TabPanel>
          <AccountSettings />
        </TabPanel>
        <TabPanel>
          <NotificationSettings />
        </TabPanel>
        <TabPanel>
          <PaymentSettings />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default SettingTabs;
