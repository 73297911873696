export function GetAgeRange(ageGroup: number[]): string {
  if (ageGroup?.length === 0) {
    return "0 years";
  }

  // Sort the array to find the minimum and maximum values
  const sortedAgeGroup = ageGroup.slice().sort((a, b) => a - b);
  const minAge = sortedAgeGroup[0];
  const maxAge = sortedAgeGroup[sortedAgeGroup.length - 1];

  return `${minAge}-${maxAge} years`;
}
